import React from "react";

function ApodHeader() {
  return (
  <div className='header-container'>
    <img className='logo' src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/NASA_logo.svg/1224px-NASA_logo.svg.png"></img>
    <h1>NASA Photo of the Day</h1>  
  </div>
  )
}


export default ApodHeader;